<template>
  <v-container grid-list-sm>
    <v-layout row wrap>
      <v-flex md12 xs12>
        <draggable v-model="formFields">
          <v-row
            v-for="(formField, index) in formFields"
            :key="formField.weight"
            class="contact-field-list-item"
          >
            <v-col cols="11" class="d-flex">
              <v-checkbox
                v-model="formField.isIncluded"
                :false-value="false"
                :disabled="formField.isDefault"
                :true-value="true"
                :label="formField.label"
                :move="handleFormFieldChanged(formField, index)"
              ></v-checkbox>
            </v-col>
            <v-col cols="1" class="d-flex float-right">
              <v-icon large color="primary">mdi-drag-vertical</v-icon>
            </v-col>
          </v-row>
        </draggable>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ContactFieldsScreenersList",
  components: {
    draggable,
  },
  props: {
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    screenerId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: [],
      includedScreenerFields: [],
    };
  },
  computed: {
    ...mapGetters("contactFieldsScreeners", {
      screenerFields: "contactFieldsScreeners",
    }),
    ...mapState("contactFields", ["contactFields"]),
  },
  methods: {
    ...mapActions("contactFields", ["getContactFields", "clearContactFields"]),
    ...mapActions("contactFieldsScreeners", [
      "getContactFieldsScreeners",
      "clearContactFieldsScreeners",
      "setContactFieldsScreeners",
    ]),
    contactFieldIdExistsInScreenerFields(contactFieldId) {
      // check if the contactFieldId exists in the screenerFields array attributes.contact_field_id
      let screenerContactField = this.includedScreenerFields.find(
        (theField) => theField.attributes.contact_field_id === contactFieldId
      );
      // if the contactFieldId exists in the includedScreenerFields array attributes.contact_field_id,
      // return the index of the screenerFields array
      if (screenerContactField) {
        return this.includedScreenerFields.indexOf(screenerContactField);
      } else {
        return -1;
      }
    },

    handleFormFieldChanged(formField, weight) {
      // check if the contactFieldId exists in the screenerFields array attributes.contact_field_id
      var screenerContactFieldExist = this.contactFieldIdExistsInScreenerFields(
        formField.id
      );

      if (screenerContactFieldExist === -1) {
        // the contactField does not exist in the screenerFields array we need to add it if it is checked
        if (formField.isIncluded) {
          // if the formField is included is checked, add it to the screenerFields
          this.includedScreenerFields.push({
            // this.screenerFields.push({
            id: null,
            type: "contactFieldsScreeners",
            attributes: {
              contact_field_id: formField.id,
              screener_id: this.screenerId,
              weight: weight + 1,
            },
          });
        }
        // if the formField is included is not checked, do nothing
      } else {
        // if the formField is included is checked, update the weight in the includedScreenerFields
        if (formField.isIncluded) {
          this.includedScreenerFields[
            screenerContactFieldExist
          ].attributes.weight = weight + 1;
        } else {
          // if the formField is included is not checked, remove it from the screenerFields
          this.includedScreenerFields.splice(
            this.includedScreenerFields.indexOf(
              this.includedScreenerFields[screenerContactFieldExist]
            ),
            1
          );
        }
      }
    },

    sortFormFields() {
      let fields = this.formFields;
      // filter out the formFields that are not included and sort by weight
      let includedFields = fields
        .filter((formField) => formField.isIncluded)
        .slice()
        .sort((a, b) => a.weight - b.weight);
      let excludedFields = fields
        .filter((formField) => !formField.isIncluded)
        .slice()
        .sort((a, b) => a.weight - b.weight);

      fields = [];
      // add included fields to the fields array then add excluded fields to the fields array
      includedFields.forEach((formField) => {
        fields.push(formField);
      });
      excludedFields.forEach((formField) => {
        if (!formField.isIncluded) {
          fields.push(formField);
        }
      });
      this.formFields = fields;
    },
  },
  async mounted() {
    await this.getContactFieldsScreeners({ screenerId: this.screenerId });
    this.includedScreenerFields = this.screenerFields;

    await this.getContactFields({ limit: 100 });

    this.formFields = this.contactFields.map((contactField) => {
      return {
        id: contactField.id,
        label: contactField.attributes.label,
        isDefault: contactField.attributes.isDefault,
        isIncluded:
          contactField.attributes.isDefault ||
          this.contactFieldIdExistsInScreenerFields(contactField.id) !== -1,
        weight: contactField.attributes.weight,
      };
    });

    this.sortFormFields();
  },
  watch: {
    screenerFields: {
      handler: function (newVal) {
        this.setContactFieldsScreeners({ data: newVal });
      },
      deep: true,
    },
  },
  destroyed() {
    this.clearContactFields();
  },
};
</script>

<style scoped>
.contact-field-list-item {
  border-bottom: 1px solid #ccc;
  cursor: grab;
}
</style>
