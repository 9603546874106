<template>
  <v-container fluid>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <validation-provider rules="required" mode="aggressive">
            <label
              :class="{
                'error--text': formMessage == null || !formMessage.length,
              }"
              >Form Message</label
            >
            <tiptap-vuetify
              v-model="formMessage"
              :extensions="extensions"
              required
              aria-required="true"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider rules="required" mode="aggressive">
            <label
              :class="{
                'error--text': consentMessage == null || !consentMessage.length,
              }"
              >Consent Message</label
            >
            <tiptap-vuetify
              v-model="consentMessage"
              :extensions="extensions"
              required
              aria-required="true"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapFields } from "vuex-map-fields";
import camelCase from "lodash.camelcase";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  HorizontalRule,
} from "tiptap-vuetify";

export default {
  name: "Messages",
  components: {
    ValidationProvider,
    TiptapVuetify,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      questionIdCheck: 0,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        HorizontalRule,
      ],
    };
  },
  computed: {
    ...mapFields("screeners", {
      acceptanceContactFormMessage: "screener.acceptanceContactFormMessage",
      acceptanceConsentMessage: "screener.acceptanceConsentMessage",
      notActiveContactFormMessage: "screener.notActiveContactFormMessage",
      notActiveConsentMessage: "screener.notActiveConsentMessage",
      notQualifiedContactFormMessage: "screener.notQualifiedContactFormMessage",
      notQualifiedConsentMessage: "screener.notQualifiedConsentMessage",
      noTrialSitesContactFormMessage: "screener.noTrialSitesContactFormMessage",
      noTrialSitesConsentMessage: "screener.noTrialSitesConsentMessage",
      trialSiteSelectedContactFormMessage:
        "screener.trialSiteSelectedContactFormMessage",
      trialSiteSelectedConsentMessage:
        "screener.trialSiteSelectedConsentMessage",
      completedContactFormMessage: "screener.completedContactFormMessage",
      completedConsentMessage: "screener.completedConsentMessage",
      translations: "screener.translations",
      qualifiedContactFormMessage: "screener.qualifiedContactFormMessage",
      qualifiedConsentMessage: "screener.qualifiedConsentMessage",
    }),
    formMessage: {
      get() {
        if (
            this.language !== "en" &&
            this.translations[this.language] &&
            this.translations[this.language][this.messageType("contact_form_message")]
        ) {
          return this.translations[this.language][
            this.messageType("contact_form_message")
          ];
        }

        let messageName = camelCase(this.type);

        return this[`${messageName}ContactFormMessage`];
      },
      set(value) {
        if (value === "<p></p>") {
          value = "";
        }
        if (this.language !== "en") {
          if (this.translations[this.language] === undefined) {
            this.translations[this.language] = [];
          }
          this.translations[this.language][
            this.messageType("contact_form_message")
          ] = value;
          this.appendScreenerTranslationMessages();
        } else {
          let messageName = camelCase(this.type);
          this[`${messageName}ContactFormMessage`] = value;
        }
      },
    },
    consentMessage: {
      get() {
        if (
            this.language !== "en" &&
            this.translations[this.language] &&
            this.translations[this.language][this.messageType("consent_message")]
        ) {
          return this.translations[this.language][
            this.messageType("consent_message")
          ];
        }
        let messageName = camelCase(this.type);

        return this[`${messageName}ConsentMessage`];
      },
      set(value) {
        if (value === "<p></p>") {
          value = "";
        }
        if (this.language !== "en") {
          if (this.translations[this.language] === undefined) {
            this.translations[this.language] = [];
          }
          this.translations[this.language][
            this.messageType("consent_message")
          ] = value;
          this.appendScreenerTranslationMessages();
        } else {
          let messageName = camelCase(this.type);
          this[`${messageName}ConsentMessage`] = value;
        }
      },
    },
  },
  methods: {
    messageType(suffix) {
      return this.type + "_" + suffix;
    },
    //used to make the translation values reactive
    appendScreenerTranslationMessages() {
      this.translations = Object.assign({}, this.translations, {});
    },
  },
};
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
