<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        top
        right
        transition="slide-x-reverse-transition"
      >
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <validation-observer ref="screenerFormObserver" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <v-card class="mx-auto mb-12">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.screenerContainer"
                ><v-container>
                  <v-row>
                    <v-col cols="12" sm="12" class="mt-3"> Screener </v-col>
                  </v-row>
                </v-container>
              </section-header>
              <v-container class="mt-5" fluid ref="screenerContainer">
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        v-model="title"
                        label="Name"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        v-model="description"
                        label="Description"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      readonly
                      v-model="sponsorName"
                      label="Sponsor"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="screenerEngagements"
                      rules="required"
                    >
                      <v-select
                        outlined
                        :items="engagementsList"
                        id="id"
                        item-text="text"
                        label="Engagements"
                        v-model="screenerEngagements"
                        chips
                        multiple
                        return-object
                        @change="updateEngagementsList"
                        :error-messages="errors"
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      :items="secondaryLanguageOptions"
                      id="id"
                      item-text="text"
                      label="Additional Languages"
                      v-model="screenerAdditionalLanguages"
                      attach
                      chips
                      multiple
                      return-object
                      @change="updateSelectedSecondaryLanguages"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        outlined
                        v-model="isLocked"
                        label="Locked"
                        :error-messages="errors"
                        :items="lockedList"
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <!-- add checkbox for is_interest_visible -->
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="isActive"
                      rules="required"
                    >
                      <v-select
                        outlined
                        v-model="isActive"
                        label="Active"
                        :items="statusList"
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="isTest" label="Test Mode"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="d-none">
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="isInterestVisible"
                      label="Display Interests on Contact Form"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <!-- SETTINGS CARD -->
            <v-card class="mx-auto mb-12 d-none">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.screenerSettingsContainer"
              >
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6" class="mt-3"> Settings </v-col>
                    <v-col cols="6" sm="6" class="mt-3"> Preview </v-col>
                  </v-row>
                </v-container>
              </section-header>

              <!-- BACK BUTTON CONTAINER -->
              <v-container class="mt-5" fluid ref="styleBackButtonContainer">
                <v-row>
                  <v-col cols="12">
                    <h3>Back Button</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="back_btn_active"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.back_btn_active"
                        prefix="#"
                        label="Active Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6" class="pa-0">
                    <v-btn
                      plain
                      class="noHover back-btn ml-4 mt-4"
                      style="cursor: none"
                      :color="
                        styles.back_btn_active
                          ? '#' + styles.back_btn_active
                          : '#' + defaultSyleColor
                      "
                    >
                      <v-icon>mdi-arrow-left-thick</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="back_btn_hover"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.back_btn_hover"
                        prefix="#"
                        label="Hover Over Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6" class="pa-0">
                    <v-btn
                      plain
                      class="noHover back-btn ml-4 mt-4"
                      :color="
                        styles.back_btn_hover
                          ? '#' + styles.back_btn_hover
                          : '#' + defaultSyleColor
                      "
                    >
                      <v-icon>mdi-arrow-left-thick</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="back_btn_inactive"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.back_btn_inactive"
                        prefix="#"
                        label="Inactive Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6" class="pa-0">
                    <v-btn
                      plain
                      class="noHover back-btn ml-4 mt-4"
                      :color="
                        styles.back_btn_inactive
                          ? '#' + styles.back_btn_inactive
                          : '#' + defaultSyleColor
                      "
                    >
                      <v-icon>mdi-arrow-left-thick</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="styles.back_btn_is_shown"
                      label="Show Back Button"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <hr />
                  </v-col>
                </v-row>
              </v-container>

              <!-- NEXT BUTTON CONTAINER -->
              <v-container class="mt-5" fluid ref="styleNextButtonContainer">
                <v-row>
                  <v-col cols="12">
                    <h3>Next Button</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="next_btn_active"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.next_btn_active"
                        prefix="#"
                        label="Active Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover white--text pa-7 font-weight-bold"
                      rounded
                      :color="
                        styles.next_btn_active
                          ? '#' + styles.next_btn_active
                          : '#' + defaultSyleColor
                      "
                    >
                      <span>Next</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="next_btn_hover"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.next_btn_hover"
                        prefix="#"
                        label="Hover Over Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover white--text pa-7 font-weight-bold"
                      rounded
                      :color="
                        styles.next_btn_hover
                          ? '#' + styles.next_btn_hover
                          : '#' + defaultSyleColor
                      "
                    >
                      <span>Next</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="next_btn_error"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.next_btn_error"
                        prefix="#"
                        label="Error Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover white--text pa-7 font-weight-bold"
                      rounded
                      :color="
                        styles.next_btn_error
                          ? '#' + styles.next_btn_error
                          : '#' + defaultSyleColor
                      "
                    >
                      <span>Next</span>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <hr />
                  </v-col>
                </v-row>
              </v-container>

              <!-- RESPONSE BUTTON CONTAINER -->
              <v-container
                class="mt-5"
                fluid
                ref="styleResopnseButtonContainer"
              >
                <v-row>
                  <v-col cols="12">
                    <h3>Response Button</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="response_btn_active"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.response_btn_active"
                        prefix="#"
                        label="Active Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover"
                      block
                      rounded
                      :color="
                        styles.response_btn_active
                          ? '#' + styles.response_btn_active
                          : '#' + defaultSyleColor
                      "
                      dark
                      elevation="2"
                      x-large
                    >
                      <span>Response</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="response_btn_hover"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.response_btn_hover"
                        prefix="#"
                        label="Hover Over Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover"
                      block
                      rounded
                      :color="
                        styles.response_btn_hover
                          ? '#' + styles.response_btn_hover
                          : '#' + defaultSyleColor
                      "
                      dark
                      elevation="2"
                      x-large
                    >
                      <span>Response</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="response_btn_error"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.response_btn_error"
                        prefix="#"
                        label="Error Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      class="noHover"
                      block
                      rounded
                      :color="
                        styles.response_btn_error
                          ? '#' + styles.response_btn_error
                          : '#' + defaultSyleColor
                      "
                      dark
                      elevation="2"
                      x-large
                    >
                      <span>Response</span>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <hr />
                  </v-col>
                </v-row>
              </v-container>

              <!-- CHECKBOX CONTAINER -->
              <v-container class="mt-5" fluid ref="styleCheckboxContainer">
                <v-row>
                  <v-col cols="12">
                    <h3>Checkbox</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="checkbox_active"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.checkbox_active"
                        prefix="#"
                        label="Active Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-checkbox
                      readonly
                      class="noHover"
                      :input-value="true"
                      :color="
                        styles.checkbox_active
                          ? '#' + styles.checkbox_active
                          : '#' + defaultSyleColor
                      "
                    >
                      <template v-slot:label>
                        <div
                          :style="{
                            color: styles.checkbox_active
                              ? '#' + styles.checkbox_active
                              : '#' + defaultSyleColor,
                          }"
                        >
                          Checkbox Active Color
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="checkbox_inactive"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.checkbox_inactive"
                        prefix="#"
                        label="Inactive Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-checkbox
                      readonly
                      class="noHover"
                      :color="
                        styles.checkbox_inactive
                          ? '#' + styles.checkbox_inactive
                          : '#' + defaultSyleColor
                      "
                    >
                      <template v-slot:label>
                        <div
                          :style="{
                            color: styles.checkbox_inactive
                              ? '#' + styles.checkbox_inactive
                              : '#' + defaultSyleColor,
                          }"
                        >
                          Checkbox Inactive Color
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="checkbox_hover"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.checkbox_hover"
                        prefix="#"
                        label="Hover Over Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-checkbox
                      readonly
                      class="noHover"
                      :input-value="true"
                      :color="
                        styles.checkbox_hover
                          ? '#' + styles.checkbox_hover
                          : '#' + defaultSyleColor
                      "
                    >
                      <template v-slot:label>
                        <div
                          :style="{
                            color: styles.checkbox_hover
                              ? '#' + styles.checkbox_hover
                              : '#' + defaultSyleColor,
                          }"
                        >
                          Checkbox Hover Over Color
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="checkbox_error"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.checkbox_error"
                        prefix="#"
                        label="Error Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-checkbox
                      readonly
                      class="noHover"
                      :input-value="true"
                      :color="
                        styles.checkbox_error
                          ? '#' + styles.checkbox_error
                          : '#' + defaultSyleColor
                      "
                    >
                      <template v-slot:label>
                        <div
                          :style="{
                            color: styles.checkbox_error
                              ? '#' + styles.checkbox_error
                              : '#' + defaultSyleColor,
                          }"
                        >
                          Checkbox Error Color
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <hr />
                  </v-col>
                </v-row>
              </v-container>

              <!-- FORM FIELD CONTAINER -->
              <v-container class="mt-5" fluid ref="styleFormFieldrContainer">
                <v-row>
                  <v-col cols="12">
                    <h3>Form Field</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="form_field_helper"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.form_field_helper"
                        prefix="#"
                        label="Helper Text Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-card-text
                      class="noHover"
                      :style="{
                        color: styles.form_field_helper
                          ? '#' + styles.form_field_helper
                          : '#' + defaultSyleColor,
                      }"
                    >
                      <span>Form field helper text color</span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="form_field_error"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.form_field_error"
                        prefix="#"
                        label="Error Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-card-text
                      class="noHover"
                      :style="{
                        color: styles.form_field_error
                          ? '#' + styles.form_field_error
                          : '#' + defaultSyleColor,
                      }"
                    >
                      <span>Form field error text color</span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="form_field_required"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.form_field_required"
                        prefix="#"
                        label="Required Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-card-text
                      class="noHover"
                      :style="{
                        color: styles.form_field_required
                          ? '#' + styles.form_field_required
                          : '#' + defaultSyleColor,
                      }"
                    >
                      <span>Form field required text color</span>
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <hr />
                  </v-col>
                </v-row>
              </v-container>

              <!-- PROGRESS BAR CONTAINER -->
              <v-container class="mt-5" fluid ref="progressBarStylesContainer">
                <v-row>
                  <v-col cols="12">
                    <h3>Progress Bar</h3>
                  </v-col>
                </v-row>

                <v-row align="start">
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="progress_bar_background"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.progress_bar_background"
                        prefix="#"
                        label="Background Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <template>
                      <v-progress-linear
                        value="50"
                        class="mt-5"
                        rounded
                        height="10"
                        :background-color="
                          styles.progress_bar_background
                            ? '#' + styles.progress_bar_background
                            : '#' + defaultProgressBarBargroundColor
                        "
                        :color="
                          styles.progress_bar_color
                            ? '#' + styles.progress_bar_color
                            : '#' + defaultSyleColor
                        "
                      ></v-progress-linear>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="progress_bar_color"
                      rules="color_hex"
                    >
                      <v-text-field
                        outlined
                        v-model="styles.progress_bar_color"
                        prefix="#"
                        label="Bar Color"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6"> </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card class="mx-auto my-12" v-if="!isNew">
              <section-header :target="() => self.$refs.messagesContainer"
                ><v-container>
                  <v-row>
                    <v-col cols="12" sm="4" class="mt-3">Messages</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-container fluid ref="messagesContainer">
                <v-tabs
                  background-color="primary"
                  center-active
                  dark
                  show-arrows
                >
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <v-tab
                    v-for="message in messageTypes"
                    :key="message.text"
                    @change="tabChanged()"
                    >{{ message.text
                    }}<v-icon
                      color="red"
                      class="d-none"
                      :ref="'tab-icon-' + message.type"
                    >
                      mdi-alert-circle-outline
                    </v-icon></v-tab
                  >
                  <v-tab-item
                    eager
                    v-for="message in messageTypes"
                    :key="message.text"
                  >
                    <validation-observer :ref="'observer-' + message.type">
                      <v-container fluid>
                        <v-tabs vertical v-model="activeLanguageTab">
                          <v-tab
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(0)"
                            >English<v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-' + message.type + '-en'"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab
                            v-for="(
                              languageOption, index
                            ) in screenerAdditionalLanguages"
                            :key="languageOption.id"
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(index + 1)"
                            >{{ languageOption.text }}
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="
                                'tab-icon-' +
                                message.type +
                                '-' +
                                languageOption.value
                              "
                            >
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-tab>

                          <v-tab-item
                            eager
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-' + message.type + '-en'"
                            >
                              <MessagesPage
                                language="en"
                                :type="message.type"
                              ></MessagesPage>
                            </validation-observer>
                          </v-tab-item>
                          <v-tab-item
                            eager
                            v-for="languageOption in screenerAdditionalLanguages"
                            :key="languageOption.id"
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="
                                'observer-' +
                                message.type +
                                '-' +
                                languageOption.value
                              "
                            >
                              <MessagesPage
                                :language="languageOption.value"
                                :type="message.type"
                              ></MessagesPage>
                            </validation-observer>
                          </v-tab-item>
                        </v-tabs>
                      </v-container>
                    </validation-observer>
                  </v-tab-item>
                </v-tabs>
              </v-container>
            </v-card>

            <!-- CONTACT FORM CARD -->
            <v-card class="mx-auto mb-12 d-none">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.screenerContactFormContainer"
                ><v-container>
                  <v-row>
                    <v-col cols="12" sm="12" class="mt-3">
                      Configure Contact Form
                    </v-col>
                  </v-row>
                </v-container>
              </section-header>
              <v-container
                class="mt-5"
                fluid
                ref="screenerContactFormContainer"
              >
                <v-row>
                  <v-col cols="12" sm="12">
                    <contact-fields-screeners-list :screener-id="screenerId">
                    </contact-fields-screeners-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapState, mapGetters } from "vuex";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import SectionHeader from "@admin/components/SectionHeader.vue";
import MessagesPage from "@admin/components/screeners/MessagesPage.vue";
import ContactFieldsScreenersList from "@admin/components/contact_fields_screeners/List.vue";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("color_hex", {
  validate: (value) => {
    return /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{2})$/.test(value);
  },
  message: "Please provide a valid hex color code.",
});

setInteractionMode("passive");

export default {
  name: "ScreenersFormView",
  data() {
    return {
      defaultTranslations: {},
      isLoading: true,
      isSubmitting: false,
      screenerId: this.$route.params.screenerId,
      snackbar: false,
      selectedSponsorId: this.$route.params.selectedSponsorId,
      statusList: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      lockedList: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      activeLanguageTab: 0,
      messageTypes: [
        {
          text: "Acknowledgement",
          type: "acceptance",
        },
        {
          text: "Not Active",
          type: "not_active",
        },
        {
          text: "Not Qualified",
          type: "not_qualified",
        },
        {
          text: "Qualified",
          type: "qualified",
        },
        {
          text: "No Trial Sites",
          type: "no_trial_sites",
        },
        {
          text: "Trial Site Selected",
          type: "trial_site_selected",
        },
        {
          text: "Completed",
          type: "completed",
        },
      ],
      self: this,

      defaultSyleColor: "AD3410",
      defaultProgressBarBargroundColor: "CCCCCC",
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    SectionHeader,
    MessagesPage,
    ContactFieldsScreenersList,
  },
  computed: {
    ...mapFields("screeners", {
      description: "screener.description",
      isActive: "screener.isActive",
      isLocked: "screener.isLocked",
      isTest: "screener.isTest",
      sponsorId: "screener.sponsorId",
      sponsorName: "screener.sponsorName",
      title: "screener.title",
      type: "screener.type",
      userId: "screener.userId",
      translations: "screener.translations",
      screenerAdditionalLanguages: "screenerAdditionalLanguages",
      isInterestVisible: "screener.isInterestVisible",
      engagements: "screener.engagements",
      screenerEngagements: "screenerEngagements",
      styles: "screener.styles",
    }),
    ...mapGetters("systemLanguages", {
      secondaryLanguageOptions: "languagesList",
    }),
    ...mapState("screeners", ["error", "screener"]),
    ...mapState("users", ["usersList"]),
    ...mapState("engagements", ["engagementsList"]),
    ...mapState("contactFieldsScreeners", ["contactFieldsScreeners"]),
    ...mapGetters("genericMessages", [
      "genericMessagesDefaultTranslations",
      "genericMessagesDefaultTranslationsListFieldCount",
      "genericMessagesDefaultTranslationsListFieldsLoaded",
    ]),
    isNew() {
      return (
        this.screenerId === undefined && this.selectedSponsorId !== undefined
      );
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setTitleObject",
      "setBreadcrumbs",
    ]),
    ...mapActions("screeners", [
      "addScreener",
      "clearScreener",
      "clearScreeners",
      "editScreener",
      "getScreener",
      "getSponsor",
      "updateEngagementScreeners",
    ]),
    ...mapActions("genericMessages", ["getDefaultTranslations"]),
    ...mapActions("users", ["getUsersList"]),
    ...mapActions("screeners", {
      toggleScreenerAdditionalLanguage: "toggleScreenerAdditionalLanguage",
    }),
    ...mapActions("systemLanguages", {
      getLanguagesList: "getLanguagesList",
    }),
    ...mapActions("engagements", ["getEngagementsList"]),
    ...mapActions("contactFieldsScreeners", [
      "updateContactFieldsScreeners",
      "clearContactFieldsScreeners",
    ]),
    getTranslationFields() {
      return Object.keys(this.defaultTranslations["default"]);
    },
    getMessageType(messageType, messageSuffix) {
      return messageType + "_" + messageSuffix;
    },
    updateActiveLanguageTab(index) {
      this.activeLanguageTab = index;
    },
    async updateSelectedSecondaryLanguages() {
      if (!this.isNew) {
        await this.toggleScreenerAdditionalLanguage(
          this.screenerAdditionalLanguages
        );
      }
      await this.updateTranslationsList();
      await this.updateAllTabIcons();
    },
    async updateTranslationsList() {
      let self = this;
      // Sort alphabetically by text property
      this.screenerAdditionalLanguages = this.screenerAdditionalLanguages
        .slice()
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        });

      this.screenerAdditionalLanguages.forEach(function (language) {
        if (self.translations[language.value] === undefined) {
          if (self.defaultTranslations[language.value]) {
            self.translations[language.value] =
              self.defaultTranslations[language.value];
          } else {
            self.translations[language.value] = self.getTranslationFields();
          }
        }
      });
    },
    updateEngagementsList() {
      let self = this;
      // Sort alphabetically by text property
      this.screenerEngagements = this.screenerEngagements
        .slice()
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        });

      const selectedEngagementForScreener = [];
      this.screenerEngagements.forEach(function (engagement) {
        selectedEngagementForScreener.push({
          engagement_id: engagement.value,
        });
      });
      self.engagements = selectedEngagementForScreener;
    },
    tabChanged() {
      this.updateAllTabIcons();
    },
    updateAllTabIcons() {
      let self = this;
      //get all messageTypes
      const messageTypes = this.messageTypes.map(function (messageType) {
        return messageType.type;
      });

      let newMessageTypes = [...messageTypes];
      //add any additional languages
      for (let i in messageTypes) {
        if (this.screenerAdditionalLanguages.length) {
          for (let j in this.screenerAdditionalLanguages) {
            newMessageTypes.push(
              messageTypes[i] + "-" + this.screenerAdditionalLanguages[j].value
            );
          }
        }
        //add default english
        newMessageTypes.push(messageTypes[i] + "-en");
      }

      //for each messageType, validate and show validation icon if invalid
      newMessageTypes.forEach(function (messageType) {
        let pageObserver = null;
        if (
          self.$refs["observer-" + messageType] !== undefined &&
          self.$refs["observer-" + messageType][0] !== undefined
        ) {
          pageObserver = self.$refs["observer-" + messageType][0];
        } else if (self.$refs["observer-" + messageType] !== undefined) {
          pageObserver = self.$refs["observer-" + messageType];
        }

        if (pageObserver !== null) {
          let tabIcons = self.$refs["tab-icon-" + messageType];

          if (!Array.isArray(tabIcons)) {
            tabIcons = [tabIcons];
          }

          pageObserver.validate().then((isValid) => {
            for (let i in tabIcons) {
              if (tabIcons[i] !== undefined) {
                if (!isValid) {
                  tabIcons[i].$el.classList.remove("d-none");
                } else {
                  tabIcons[i].$el.classList.add("d-none");
                }
              }
            }
          });
        }
      });
    },
    async submit() {
      //update all tab validation icons
      this.updateAllTabIcons();

      if (this.isSubmitting) return;

      //check form validation
      if (!(await this.$refs.screenerFormObserver.validate())) {
        return;
      }

      let success = false;
      let engagementScreenerUpdateSuccess = false;
      this.isSubmitting = true;

      if (this.isNew) {
        success = await this.addScreener(this.selectedSponsorId);
      } else {
        success = await this.editScreener(this.screenerId);
        if (success) {
          // update contactFieldsScreeners
          let contactFieldsScreenersSuccess =
            await this.updateContactFieldsScreeners({
              screenerId: this.screenerId,
              contactFieldScreeners: this.contactFieldsScreeners,
            });
          // if contactFieldsScreenersSuccess is false, set success to false
          if (!contactFieldsScreenersSuccess) {
            success = false;
          }
        }
      }
      engagementScreenerUpdateSuccess = await this.updateEngagementScreeners();

      if (success !== false && engagementScreenerUpdateSuccess !== false) {
        this.clearScreeners(); // force re-pull from db
        if (this.isNew) {
          this.screenerId = success.data.id;
        }
        // clear the contactFieldsScreeners
        this.clearContactFieldsScreeners();

        const redirect = {
          name: "ScreenerView",
          params: { screenerId: this.screenerId },
        };
        await this.$router.push(redirect);
      } else {
        this.snackbar = !!this.error;
      }

      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getLanguagesList();
    this.setBreadcrumbs();
    let actionButtons = [];
    if (this.isNew) {
      this.clearScreener();
      await this.getSponsor(this.selectedSponsorId);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/sponsors/view/" + this.selectedSponsorId,
        },
        index: 0,
      });
      this.setTitleObject("Screener");
    } else {
      await this.getScreener({ id: this.screenerId }).catch(() => {
        this.$router.push({ name: "NotFound" });
      });

      if (
        this.genericMessagesDefaultTranslations === undefined ||
        Object.keys(this.genericMessagesDefaultTranslations).length === 0
      ) {
        await this.getDefaultTranslations();
      }
      this.defaultTranslations = this.genericMessagesDefaultTranslations;

      // if any of the existing additional languages does not have a translation, add it's default translation
      await this.updateSelectedSecondaryLanguages();

      this.setTitleObject(this.screener.title);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/screeners/view/" + this.screenerId,
        },
        index: 0,
      });
    }

    await this.getEngagementsList(
      this.isNew ? this.selectedSponsorId : this.sponsorId
    );

    await this.getUsersList();
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
    this.isLoading = !(
      this.genericMessagesDefaultTranslationsListFieldsLoaded ===
      this.genericMessagesDefaultTranslationsListFieldCount
    );
  },
  updated() {
    //after mounted load any validation icons
    this.updateAllTabIcons();
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearScreener();
  },
};
</script>

<style scoped>
.back-btn {
  background-color: transparent !important;
  border: 0 solid #000;
  justify-content: left !important;
  padding: 0 !important;
}
.noHover {
  cursor: none !important;
  pointer-events: none;
}
</style>
